// extracted by mini-css-extract-plugin
export var carousel = "BlogPost-module--carousel--2c92f";
export var container = "BlogPost-module--container--e7abb";
export var containerFlex = "BlogPost-module--container-flex--700a2";
export var content = "BlogPost-module--content--91f75";
export var header = "BlogPost-module--header--4c8b1";
export var image = "BlogPost-module--image--bf990";
export var markdown = "BlogPost-module--markdown--3793b";
export var otherArticles = "BlogPost-module--otherArticles--ff4b7";
export var publicationDate = "BlogPost-module--publicationDate--f2b21";
export var returnContainer = "BlogPost-module--returnContainer--c3446";
export var subInfos = "BlogPost-module--subInfos--0fe94";
export var text = "BlogPost-module--text--fd347";
export var textOrange = "BlogPost-module--text-orange--3e971";
export var thumb = "BlogPost-module--thumb--8eba0";
export var titleContainer = "BlogPost-module--titleContainer--e9604";
export var userContainer = "BlogPost-module--userContainer--b481b";
export var userInfos = "BlogPost-module--userInfos--ed0d9";
export var userPicContainer = "BlogPost-module--userPicContainer--49f95";