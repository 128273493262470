import React from 'react';

import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import {
  FormattedDate,
  FormattedMessage,
  useIntl,
} from 'gatsby-plugin-react-intl';

import Button from '../components/Button/Button';
import BlogCard from '../components/Cards/BlogCard/BlogCard';
import CustomCarousel from '../components/CustomCarousel/CustomCarousel';
import Layout from '../components/Layout/Layout';
import ArticleSEO from '../components/Seo/ArticleSEO';
import ArrowTip from '../components/Svgs/ArrowTip/ArrowTip';
import MainTitle from '../components/Title/MainTitle/MainTitle';
import SecondaryTitle from '../components/Title/SecondaryTitle/SecondaryTitle';
import ArticlePreviewType from '../types/ArticlePreview';

import * as css from './BlogPost.module.scss';

export default function ArticleDetails({ data }) {
  const {
    details,
    allBlogPost,
    productImage,
  }: {
    details: any;
    allBlogPost: { nodes: ArticlePreviewType[] };
    productImage: any;
  } = data;
  const { html } = details;
  const {
    title,
    userPic,
    userName,
    userJob,
    tags,
    featuredImg,
    date,
    metaDescription,
  } = details.frontmatter;

  const productImageSrc = productImage.edges[0]?.node.fluid.src;

  const intl = useIntl();

  return (
    <Layout>
      <ArticleSEO
        title={title}
        description={metaDescription}
        keywords={tags}
        image={featuredImg}
        productImage={productImageSrc}
        author={userName}
        publishDate={date}
      />
      <div className="container-flex">
        <div style={{ marginTop: '50px' }} className="container">
          <div className={css.container}>
            <div className={css.header}>
              <div className={css.returnContainer}>
                <Button
                  titleId={null}
                  variant={'secondary'}
                  icon={
                    <ArrowTip
                      direction="left"
                      variant="secondary"
                      height={16}
                    />
                  }
                  link={''}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <div className={css.text}></div>
                <FormattedMessage
                  id="blog.previousButton.title"
                  defaultMessage={'Previous page'}
                  description="Previous button"
                />
              </div>
              <div className={css.userContainer}>
                <div className={css.userPicContainer}>
                  <Img fluid={userPic.childImageSharp.fluid} alt={userName} />
                </div>
                <div className={css.userInfos}>
                  <div>{userName}</div>
                  <div className={css.subInfos}>{userJob}</div>
                </div>
              </div>
            </div>
            <div className={css.thumb}>
              <Img
                className={css.image}
                fluid={featuredImg.childImageSharp.fluid}
                alt={title}
              />
            </div>
            <div className={css.content}>
              <div className={css.titleContainer}>
                <div className={css.publicationDate}>
                  <FormattedMessage
                    id="blog.published"
                    defaultMessage="Published"
                    description="Published"
                  />{' '}
                  <FormattedDate
                    value={new Date(date)}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                </div>
                <MainTitle titleId={title} subtitleId={tags} />
              </div>
              <div
                className={css.markdown}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            <div className={css.otherArticles}>
              <SecondaryTitle
                size="large"
                titleId="Other articles"
                addSunshines={false}
                subtitleId=" "
              />
              <div className={css.carousel}>
                <CustomCarousel>
                  {allBlogPost.nodes
                    .filter(function (blogPost) {
                      return blogPost.frontmatter.language === intl.locale;
                    })
                    .map(blogPost => (
                      <BlogCard
                        key={Math.random()}
                        textId={blogPost.frontmatter.metaDescription}
                        titleId={blogPost.frontmatter.title}
                        subtitleId={blogPost.frontmatter.tags}
                        image={blogPost.frontmatter.thumb.childImageSharp.fluid}
                        link={`/blog/${blogPost.frontmatter.slug}`}
                      />
                    ))}
                </CustomCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ProjectDetails($slug: String) {
    details: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        userName
        userJob
        title
        metaDescription
        tags
        date
        language
        featuredImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        userPic {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allBlogPost: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          language
          title
          tags
          date
          slug
          metaDescription
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    productImage: allImageSharp(
      filter: { fluid: { originalName: { eq: "App1.png" } } }
    ) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
  }
`;
