import React, { useEffect, useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { breakpoints } from '../../constants/breakpoints';
import { useDimension } from '../../hooks/useDimension';
import BlogCard from '../Cards/BlogCard/BlogCard';
import ArrowTip from '../Svgs/ArrowTip/ArrowTip';

import * as css from './CustomCarousel.module.scss';

export type CustomCarouselType = {
  elementsPerSlide?: number;
  children: JSX.Element[];
};

const CustomCarousel = ({
  elementsPerSlide,
  children: childrens,
}: CustomCarouselType) => {
  if (!elementsPerSlide) elementsPerSlide = 1;

  const dimensions = useDimension();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [processedChildrens, setProcessedChildrens] = useState([]);
  const [lastButton, setLastButton] = useState<'next' | 'previous'>('next');

  const handlePrevious = () => {
    setLastButton('previous');
    if (currentSlide == 0) setCurrentSlide(childrens.length - 1);
    else setCurrentSlide(currentSlide - 1);
  };
  const handleNext = () => {
    setLastButton('next');
    if (currentSlide == childrens.length - 1) setCurrentSlide(0);
    else setCurrentSlide(currentSlide + 1);
  };

  useEffect(() => {
    const arrayToDisplay = [];
    const previousIndex =
      currentSlide == 0 ? childrens.length - 1 : currentSlide - 1;
    const nextIndex =
      currentSlide + elementsPerSlide >= childrens.length
        ? (currentSlide + elementsPerSlide) % childrens.length
        : currentSlide + elementsPerSlide;
    arrayToDisplay.push(
      <div
        key={previousIndex}
        className={
          lastButton === 'next'
            ? `${css.previous} ${css.slideOutLeft}`
            : `${css.previous} ${css.slideOutRight}`
        }
      >
        {childrens[previousIndex]}
      </div>
    );

    for (let i = 0; i < elementsPerSlide; i++) {
      const index = (currentSlide + i) % childrens.length;
      arrayToDisplay.push(
        <div
          key={Math.random()}
          className={
            lastButton === 'next'
              ? `${css.active} ${css.slideInLeft}`
              : `${css.active} ${css.slideInRight}`
          }
        >
          {childrens[index]}
        </div>
      );
    }
    arrayToDisplay.push(
      <div
        key={nextIndex}
        className={
          lastButton === 'previous'
            ? `${css.next} ${css.slideOutRight}`
            : `${css.next} ${css.slideOutLeft}`
        }
      >
        {childrens[nextIndex]}
      </div>
    );
    setProcessedChildrens(arrayToDisplay);
  }, [currentSlide]);

  return (
    <div
      style={{ '--elementsPerSlide': elementsPerSlide } as React.CSSProperties}
    >
      <div className={css.container}>
        {dimensions.width > breakpoints.tablet.max && (
          <div onClick={handlePrevious} className={`${css.button} ${css.left}`}>
            <ArrowTip direction="left" height={16} variant="secondary" />
          </div>
        )}
        <div className={css.slidesContainer}>{processedChildrens}</div>
        {dimensions.width > breakpoints.tablet.max && (
          <div onClick={handleNext} className={`${css.button} ${css.right}`}>
            <ArrowTip direction="right" height={16} variant="secondary" />
          </div>
        )}
      </div>
      {dimensions.width <= breakpoints.tablet.max && (
        <div className={css.tabletButtons}>
          <div onClick={handlePrevious} className={`${css.button} ${css.left}`}>
            <ArrowTip direction="left" height={23} variant="secondary" />
          </div>
          <div onClick={handleNext} className={`${css.button} ${css.right}`}>
            <ArrowTip direction="right" height={23} variant="secondary" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCarousel;
