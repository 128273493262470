// extracted by mini-css-extract-plugin
export var button = "CustomCarousel-module--button--da6cc";
export var container = "CustomCarousel-module--container--02b84";
export var containerFlex = "CustomCarousel-module--container-flex--470a4";
export var left = "CustomCarousel-module--left--aeb7a";
export var right = "CustomCarousel-module--right--8ccf9";
export var slideInLeft = "CustomCarousel-module--slideInLeft--e498f";
export var slideInRight = "CustomCarousel-module--slideInRight--d89b0";
export var slideOutLeft = "CustomCarousel-module--slideOutLeft--8e8ac";
export var slideOutRight = "CustomCarousel-module--slideOutRight--4c52f";
export var slide_in_left = "CustomCarousel-module--slide_in_left--a417d";
export var slide_in_right = "CustomCarousel-module--slide_in_right--e0fc8";
export var slide_out_left = "CustomCarousel-module--slide_out_left--0ca25";
export var slide_out_right = "CustomCarousel-module--slide_out_right--283c4";
export var slidesContainer = "CustomCarousel-module--slidesContainer--d38b0";
export var tabletButtons = "CustomCarousel-module--tabletButtons--b9890";
export var textOrange = "CustomCarousel-module--text-orange--70942";