import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';

import ArticleJsonLd from './ArticleJsonLd';
import JsonLd from './JsonLd';

export type SEOProps = {
  title: string;
  description: string;
  keywords: string;
  image: string;
  productImage: string;
  author: string;
  publishDate: string;
};

const ArticleSEO = ({
  title,
  description,
  keywords,
  author,
  publishDate,
  image,
  productImage,
}: SEOProps) => {
  const intl = useIntl();
  return (
    <>
      <Helmet
        defaultTitle={title}
        lang={intl.formatMessage({
          id: 'seo.lang',
        })}
        description={description}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: `og:image`,
            content: `https://www.autocut.fr${image.childImageSharp.fluid.src}`,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:image`,
            content: `https://www.autocut.fr${image.childImageSharp.fluid.src}`,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: 'twitter:site',
            content: '@ToolPad_fr',
          },
          {
            name: 'twitter:creator',
            content: '@ToolPad_fr',
          },
        ]}
      />
      <JsonLd productImageUrl={productImage} />
      <ArticleJsonLd
        title={title}
        description={description}
        keywords={keywords}
        author={author}
        imageUrl={`https://www.autocut.fr${image.childImageSharp.fluid.src}`}
        datePublished={publishDate}
      />
    </>
  );
};

export default ArticleSEO;
